import React from 'react';

function CustomModalReact({ isOpen, onClose, children }) {
    return (
        <div className={`modalmy ${isOpen ? 'showmy' : ''}`} onClick={onClose}>
            <div className="modal-contentmy" onClick={(e) => e.stopPropagation()}>
                <span className="close-buttonmy" onClick={onClose}>&times;</span>
                <div className="flex flex-col justify-center items-center space-y-2 mt-4">
                    {children}
                </div>
            </div>
        </div>
    );
}
export function CustomModalReactFriend({ isOpen, onClose, children }) {
    return (
        <div className={`modalmy ${isOpen ? 'showmy' : ''}`} onClick={onClose} style={{height:'40dvh'}}>
            <div className="modal-contentmy" onClick={(e) => e.stopPropagation()}>
                <span className="close-buttonmy" onClick={onClose}>&times;</span>
                <div className="flex flex-col justify-center items-center space-y-2 mt-4">
                    {children}
                </div>
            </div>
        </div>
    );
}
export function CustomModalReactWallet({ isOpen, onClose, children }) {
    return (
        <div className={ `modalmy ${isOpen ? 'showmy ' : ''}`} onClick={onClose} style={{height:'40dvh'}}>
            <div className="modal-contentmy" onClick={(e) => e.stopPropagation()}>
                <span className="close-buttonmy" onClick={onClose}>&times;</span>
                <div className="flex flex-col justify-center items-center space-y-2 mt-4">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default CustomModalReact;
