import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../UserContext';
import contentComponent from './Getcontext';

function BreathingButton() {
  const { distance, setDistance, total_distance, setTotal_distance, user } = useContext(UserContext);
  const [isScrolling, setIsScrolling] = useState(false);

  // Получаем текстовые строки из JSON
  const element = contentComponent({ language: localStorage.getItem('language_code') || 'en', contextKey: 'element' });

  useEffect(() => {
    let scrollTimeout;

    const handleScroll = () => {
      setIsScrolling(true);

      // Убираем класс через 1 секунду после окончания прокрутки
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 1000);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  async function updateDistance(distance) {
    try {
      console.log("user", user);
      const response = await fetch(`https://drivecoin.io:3001/api/update-distance/${user.id}`, {
        method: 'PUT',
      });
      const results = await response.json();
      console.log("Данные успешно обновлены");
    } catch (error) {
      console.error('Ошибка обновления данных клайма:', error);
      return false;
    }
    setDistance(0);
    setTotal_distance(total_distance + distance);
  }

  return (
    <button
      className="button-custom mb-4 rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50"
      disabled={distance ? "true" : ""}
      onClick={() => updateDistance(distance)}
    >
      {distance ? `${element.claim} ${distance}` : element.connect_elm}
    </button>
  );
}

export default BreathingButton;
