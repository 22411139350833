import React  from 'react';
import ListGroupItem from './ListGroupItem';



import taskData from './context/task.json';
import contentComponent from './Getcontext';

const language = localStorage.getItem('language_code') || 'en';
const cards = taskData.cards[language];
const element = contentComponent({language:language, contextKey:'element'});
function Task() {
  
  return <div className='min-h-[100dvh] bg-gradient-to-b from-[#1a0b2e] to-[#0f0618] text-white p-4' style={{ position: 'relative' }}>
    
    
    {/* <div className="globe"></div>; */}

    <img src={require('../img/task4.webp')} alt="task" className='rounded-lg mx-auto mb-4 shadow-lg from-[#1a0b2e] to-[#0f0618] w-full' />
    <h1 className='text-2xl font-bold text-center mb-4 '>{element.task}</h1>
    <ListGroupItem items={cards} />
    
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    </div>
    
  
  
  
  
  
  
  
  
}

export default Task;
