import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { BottomNav } from './components/BottomTabNavigatorTailwin.js';
import { UserContext } from './UserContext';
import  UserLoader  from './UserLoader';
import { Preload } from './components/preload';
import { TonConnectUIProvider , THEME} from '@tonconnect/ui-react';

function App() {
  
  const { setUser , user} = useContext(UserContext);
  let [initdata1, setInitdata1] = useState(null);
  
  function extractStartParam(initDataString) {
    try {
      // Создаем объект URLSearchParams из строки
      const params = new URLSearchParams(initDataString);
  
      // Извлекаем параметр 'start_param'
      const startParam = params.get('start_param');
      if (startParam) {
        console.log('Параметр старта:', startParam);
        return startParam;
      } else {
        console.error('Параметр "start_param" не найден.');
        return null;
      }
    } catch (error) {
      console.error('Ошибка при извлечении параметра:', error);
      return null;
    }
  }
  
  useEffect(() => {
    let attempts = 0;
    const maxAttempts = 5; // Максимальное количество попыток
    const intervalTime = 2000; // Интервал между попытками в миллисекундах

   async function applyTelegramStyles() {
      console.log("Проверка наличия Telegram API...");

      const tg = window.Telegram;
      console.log(tg);
      if (tg && tg.WebApp) {
        console.log("Telegram API доступен, инициализация...");      
       let initdata1 = window.Telegram.WebApp.initData;
        // if (initdata1) {
        //    const startParam = extractStartParam(initdata1);
        //   setInitdata1(startParam);
        //   console.log("initdata1", initdata1);
        //  }


        
        const initData = tg.WebApp.initDataUnsafe;
        if (initData && initData.user && initData.user.id && !user && initData.hash ) {
          console.log("Приложение запущено в Telegram.");
          console.log(tg.WebApp);
          console.log("Window", window);
          // Устанавливаем стили после проверки
          tg.WebApp.setBackgroundColor('#212529');
          tg.WebApp.setBottomBarColor("#212529");
          tg.WebApp.setHeaderColor('#383a3b');
          tg.WebApp.enableVerticalSwipes();
          tg.WebApp.ready(); 
          tg.WebApp.expand();
          setTimeout(() => {
            setUser(initData);
            return true;
          }, 3000);
          

           // Успешная инициализация
        } else {
          console.log("Приложение запущено не в Telegram.");
        }
      } else {
        console.error('Telegram WebApp API недоступен.');
      }
      return false; // Неуспешная инициализация
    }

    const interval = setInterval(() => {
      if (applyTelegramStyles() || attempts >= maxAttempts) {
        clearInterval(interval);
      }
      attempts++;
    }, intervalTime);

    // Очистка интервала при размонтировании компонента
    return () => clearInterval(interval);
  }, [user]);

 

  return (
    
    <TonConnectUIProvider manifestUrl="https://wintext.ru/manifest.json" 
    uiPreferences={{theme: THEME.DARK}}
    walletsListConfiguration={{
      includeWallets: [
        {
          appName: "DriveCoin",
          name: "DriveCoin",
          imageUrl: "https://wintext.ru/icon.png",
          aboutUrl: "https://wintext.ru",
          jsBridgeKey: "drivecoin",
          platforms: ["ios", "android"]
        }
      ]
    }}
    actionsConfiguration={{
      twaReturnUrl: 'https://t.me/Drive_Coin_Official_bot/DriveCoin'
    }}
    > 

    <Router>
      <Preload />    
      <UserLoader />    
      <BottomNav />
    </Router>
    </TonConnectUIProvider>
    
  );  
}

export default App;
