import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from '../UserContext';
import { User, Settings } from 'lucide-react';
import { Link } from 'react-router-dom';

function TelegramAccount(props) {
    const { user, user_photo_url } = useContext(UserContext);
    const [user_photo_url_state, setUser_photo_url_state] = useState(false);
    useEffect(() => {
        if(user_photo_url) {
            setUser_photo_url_state(true);
        }
    }, [user, user_photo_url]);
    
    if (!user) {
        return <p>Loading user data...</p>; // Или другой индикатор загрузки
    }

    return (
        <div className="flex items-center gap-3 py-6">

            {user_photo_url_state ? 
            <div className="rounded-full border-2 border-[#4f46e5]">
            <img src={user_photo_url} alt="User Avatar" className="rounded-full h-8 w-8" /> 
            </div>
            :            
           <div className="rounded-full bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] p-2.5 shadow-lg shadow-indigo-500/50">
             <User className="h-6 w-6 text-white" />
            </div>            
            }
            <div>
                <h2 className="text-lg font-bold text-white">{user.user.username}</h2>
                <p className="text-sm text-gray-400 mb-0">Premium User</p>
            </div>

            <div className="ml-auto">
            <Link to="/Setting" className="text-blue-500 hover:underline">
            <Settings className="h-6 w-6 text-gray-400" />   
            </Link>
            </div>
            <div style={{color: '#7c3aed', display: 'flex', alignItems: 'center', fontSize: '10px'}}> {user.user.id}</div>
        </div>
    );
}

export default TelegramAccount;
