import React, { useState, useEffect } from "react";
import { TonConnectButton, useTonConnectUI, useTonAddress } from "@tonconnect/ui-react";
import contentComponent from "./Getcontext";
import {CustomModalReactWallet} from "./CustomModalReact";
import { ToastContainer, toast } from "react-toastify";
const WalletConnectButton = () => {
    const [tonConnectUI] = useTonConnectUI();
    const language = localStorage.getItem('language_code');
    const element = contentComponent({ language: language, contextKey: 'element' });
    const tonAddress = useTonAddress();
    const [tokens, setTokens] = useState([]);
    const [showMenu, setShowMenu] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);  
    const copyTonAddress = () => {
        navigator.clipboard.writeText(tonAddress);
        toast.info('Ton address copied to clipboard',{
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
        });
    }
    const disconnectWallet = async () => {
        setShowModal(false);
        handleClose();
        console.log('disconnecting wallet');
        try{
        await  tonConnectUI.disconnect();
        }catch(error){
            console.log(error);
        }


    };

    const fetchTokens = async (walletAddress) => {
        try {
            console.log('fetching tokens');
            const response = await fetch(`https://tonapi.io/v1/account/getBalance?account=${walletAddress}`, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_TONAPI_API_KEY}`,
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            });
            const data = await response.json();
            setTokens(data.tokens || []);
        } catch (error) {
            console.error("Ошибка при получении токенов:", error);
        }
    };  
    useEffect(() => {
        if (tonAddress) {
            fetchTokens(tonAddress);
        }
    }, []);

    return (
        <div>
            {tokens.length > 0 && (
                <div>
                    <p>Адрес кошелька: {tonAddress}</p>
                    <h2>Список токенов:</h2>
                    <ul>
                        {tokens.map((token, index) => (
                            <li key={index}>
                                <strong>{token.symbol}</strong>: {token.balance}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {tonAddress ? (
                <div
                    className="text-white cursor-pointer text-sm text-center "
                    onClick={() => setShowModal(true)}
                >
                    <span className=" border border-gray-400 border-1 rounded-3xl px-4  py-1 flex items-center w-fit mx-auto justify-center">
                        <img src={require('../img/ton_gd.png')} alt="wallet icon" className="w-4 h-4 mr-2 bg-white rounded-full overflow-hidden" />
                        {tonAddress.slice(0, 4)}...{tonAddress.slice(tonAddress.length-4,tonAddress.length)}

                    </span>
                   
                </div>
                
            ) : (
                <button className="button-custom mb-4 rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50"
                    onClick={() => tonConnectUI.openModal()}>
                     Connect Wallet 
                </button>
            )}
<ToastContainer />
<CustomModalReactWallet isOpen={showModal} onClose={handleClose}>
        <div className="absolute top-1/4 left-0 right-0 flex flex-col space-y-4 items-center w-full mt-4">
          
          <button onClick={disconnectWallet } className="bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] text-white rounded-lg p-2 w-3/4">
            Disconnect Wallet
          </button>
          <button onClick={copyTonAddress } className="bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] text-white rounded-lg p-2 w-3/4">
            Copy Ton Address
          </button>
        </div>
        </CustomModalReactWallet>
        </div>
    
    );
};

export default WalletConnectButton;