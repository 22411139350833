import React from "react";
import WalletConnectButton from "./WalletConnectButton";

const Assets = () => {
    return (
        <div className='min-h-[100dvh] bg-gradient-to-b from-[#1a0b2e] to-[#0f0618] text-white p-4' style={{ position: 'relative' }}>
           <div className='text-center text-2xl mb-2 mt-4'>Assets</div>
           <div className='text-center text-sm mb-4 text-gray-400'>You can see your assets here.</div>
            <WalletConnectButton />
        </div>
    );
};

export default Assets;