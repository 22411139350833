import { useEffect, useContext, useState } from 'react';
import { UserContext } from './UserContext';
import { useLocation } from 'react-router-dom';


function UserLoader() {
  const { user, setUser,  setDistance, setTotal_distance, setTotalReferrals, setReferralsclaim, setUser_photo_url } = useContext(UserContext);
  let [hash , setHash] = useState("нет хеша");
  const location = useLocation();
      
    async function newUser(){
      let refferer = "";
      let startParam = "";
      if (user.start_param ) {
        startParam = user.start_param;
        console.log('Параметр старта:', startParam.includes('ref_'));
        refferer = startParam.toString().split("_")[1]; 
        setHash(refferer);
        //ref_167926219
      } else {
        console.log('Параметр старта:', false);
        refferer = "1";
      }
      
      console.log("user перед созданием", refferer)
      await fetch('https://drivecoin.io:3001/api/create-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'charset': 'utf-8'
        },
        body: JSON.stringify({
          userId: user.user.id,
          allows_write_to_pm: user.user.allows_write_to_pm,
          user_name_tg: user.user.username ? user.user.username : 'No',
          first_name: user.user.first_name ? user.user.first_name : 'No',
          last_name: user.user.last_name ? user.user.last_name : 'No',
          language_code: user.user.language_code ? user.user.language_code : 'No',
          refferer: refferer,

        }),
      })
      .then(response => response.json())
      .then(data => {
        console.log("data", data);
        if (data.photoUrl && data.photoUrl !== "null") {
          setUser_photo_url(data.photoUrl);
          console.log("photoUrl", data.photoUrl);
        }
        if (data.error) {
          console.error('Ошибка при создании пользователя в базе данных:', data.error);
        } else if (data.message === 'Пользователь успешно создан') {
          
          console.log('Пользователь успешно создан в базе данных.');
          localStorage.setItem('userId', user.user.id);
          localStorage.setItem('language_code', user.user.language_code);
        }
      })
      .catch(error => {
        console.error('Ошибка при обращении к API:', error);
        localStorage.setItem('userId', user.user.id.toString());
      });
      
    }


  async function fetchData(user) {
    try {
      console.log("user", user.user.id);
      const response = await fetch(`https://drivecoin.io:3001/api/data/${user.user.id}`);
      
      // Проверка на пустой ответ
      if (!response.ok) {
        throw new Error(`Ошибка сети: ${response.status}`);
      }

      const results = await response.json();
      console.log("results", results)
      if(results.code === 202){
        console.log("new user")
        newUser(user);
      }
      if (results.error) {
        console.error('Ошибка при получении данных:', results.error);
      } else {
        if (results) {
          if (results.distance) {
            setDistance(results.distance);
          }
          if (results.total_distance) {
            setTotal_distance(results.total_distance);
          }
          if (results.totalReferrals) {
            setTotalReferrals(results.totalReferrals);
          }
          if (results.distans_ref_level_1 + results.distans_ref_level_2 + results.distans_ref_level_3) {
            setReferralsclaim(results.distans_ref_level_1 + results.distans_ref_level_2 + results.distans_ref_level_3);
          }
          if (results.photo_url) {
            setUser_photo_url(results.photo_url);
          }
        } else {
          setTimeout(() => {
            newUser(user)
          }, 1000);
        }
      }
    } catch (error) {
      console.error('Ошибка получения данных:', error);
    }
  }
  
  useEffect(() => {
    if (user && user.user && user.user.id && user.hash) {
     
    } 
  }, [user]);


  useEffect(() => {
    const interval = setInterval(() => {
      if (user && user.user && user.user.id && user.hash) {      
        fetchData(user);
        clearInterval(interval);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [user]);

return null; // Компонент не рендерит ничего
}

export default UserLoader;