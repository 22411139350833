import React from 'react';

function CustomModal({ isOpen, onClose, children }) {
    return (
        <div className={`modalmy ${isOpen ? 'showmy' : ''}`} onClick={onClose}>
            <div className="modal-contentmy" onClick={(e) => e.stopPropagation()}>
                <span className="close-buttonmy" onClick={onClose}>&times;</span>
                <div dangerouslySetInnerHTML={{ __html: children }} />
            </div>
        </div>
    );
}

export default CustomModal;
