import React, { useContext, useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import { Users, User } from 'lucide-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from '../UserContext';
import CustomModalReact, { CustomModalReactFriend } from './CustomModalReact';

function Friend() {
  const { user, cards, setPage, loading, totalReferrals, referralsclaim, setReferralsclaim, setTotal_distance, total_distance } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);

  const getCachedImage = (url) => {
    const cachedImage = localStorage.getItem(url);
    if (cachedImage) {
      return cachedImage;
    }
    return url;
  };

  const cacheImage = (url) => {
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.blob();
      })
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = () => {
          localStorage.setItem(url, reader.result);
        };
        reader.readAsDataURL(blob);
      })
      .catch(error => {
        console.error('Ошибка при загрузке изображения:', error);
      });
  };

  useEffect(() => {
    cards.forEach(card => {
      if (card.img) {
        cacheImage(card.img);
      }
    });
  }, [cards]);

  const handleInviteClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const copyReferralLink = () => {
    const referralLink = `https://t.me/Drive_Coin_Official_bot/DriveCoin?startapp=ref_${user.user.id}`;
    navigator.clipboard.writeText(referralLink)
      .then(() => {
        toast.info("Referral link copied to clipboard!",{
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      })
      .catch(err => {
        toast.error("Error copying link: " + err);
      });
  };

  const openTelegramShare = () => {
    const referralLink = `https://t.me/Drive_Coin_Official_bot/DriveCoin?startapp=ref_${user.user.id}`;
    window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${referralLink}&text=DriveCoin`);
  };

  const updateDistanceRef = () => {
    fetch(`https://drivecoin.io:3001/api/update-distance-ref/${user.user.id}`)
      .then(response => response.json())
      .then(data => console.log(data));
    setTotal_distance(total_distance + referralsclaim);
    setReferralsclaim(0);
  };

  return (
    <div className='min-h-[100dvh] bg-gradient-to-b from-[#1a0b2e] to-[#0f0618] text-white p-4' style={{ position: 'relative' }}>
      <ToastContainer />
      <div className='text-center text-2xl mb-2 mt-4'>Invite Your Friends </div>
      <div className='text-center text-sm mb-4 text-gray-400'>You and your friends will receive bonuses.</div>

      <div className=" overflow-hidden rounded-2xl bg-gradient-to-br from-[#2e1065] to-[#4c1d95] p-6 shadow-[0_0_15px_rgba(139,92,246,0.3)]">
        <div className="mb-4 flex items-center justify-between">
          <h3 className="text-lg font-medium text-gray-200">Friends: {totalReferrals}</h3>
          <Users className="h-6 w-6 text-violet-400" />
        </div>
        <div className="text-4xl font-bold text-white">
          {referralsclaim} <span className="text-sm text-gray-400"></span>
        </div>
        <button className="mt-2 text-sm font-bold text-gray-400 border border-gray-400 rounded-lg p-1"
          onClick={() => {
            updateDistanceRef();
          }}
        >Claim tokens</button>
      </div>
      <div className='text-center pl-2 pr-2 mb-5 mt-2   text-gray-400' style={{ fontSize: '12px' }}>
        You receive 10% from 1st-level referrals,
        5% from 2nd-level referrals, and 1% from 3rd-level referrals.</div>
      <button
        className="button-custom mb-4 rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50"
        onClick={handleInviteClick}
      >
        Invite Friend
      </button>

      <div className="space-y-2" style={{ marginBottom: '250px' }}>
        {cards.map((item, index) => (
          <div
            key={index}
            className="overflow-hidden border-0 bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 shadow-[0_0_15px_rgba(139,92,246,0.15)] p-2 rounded-lg"
          >
            <div className="flex items-start">
              {item.img ? (
                <Image
                  src={getCachedImage(item.img)}
                  roundedCircle
                  className="w-8 h-8 object-cover mr-4 rounded-full"
                />
              ) : (
                <div className="rounded-full bg-gradient-to-r mr-4 object-cover from-[#f97316] to-[#f59e0b]">
                  <User className="w-8 h-8 object-cover text-white rounded-full" />
                </div>
              )}
              <div>
                <h2 className="text-md text-white">{item.title}</h2>
                <p className="text-sm text-gray-400">{item.level}</p>
              </div>
              <div className='text-md text-white ml-auto pr-2 '>{item.distance ? item.distance : 0} dp</div>
            </div>
          </div>
        ))}
        <div
          className="mt-2 text-sm font-bold text-center text-gray-400 rounded-lg p-1 cursor-pointer transition-colors duration-200"
          onClick={() => setPage(prevPage => prevPage + 1)}
        >
          <span className='border-b border-gray-400'>Show more</span>

        </div>

      </div>

      {loading && <div className="text-center text-white">Loading...</div>}
      <CustomModalReactFriend isOpen={showModal} onClose={handleClose}>
        <div className="absolute top-1/4 left-0 right-0 flex flex-col space-y-4 items-center w-full mt-4">
          <button onClick={copyReferralLink} className="bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] text-white rounded-lg p-2 w-3/4">
            Copy Referral Link
          </button>
          <button onClick={openTelegramShare} className="bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] text-white rounded-lg p-2 w-3/4">
            Share via Telegram
          </button>
        </div>
      </CustomModalReactFriend>
    </div>
  );
}

export default Friend;
