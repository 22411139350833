import React, { createContext, useState, useEffect } from 'react';

// Создаем контекст
export const UserContext = createContext();

// Создаем провайдер
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [distance, setDistance] = useState(0);
  const [total_distance, setTotal_distance] = useState(0);
  const [cards, setCards] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  const [tonConnect, setTonConnect] = useState(null);
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [referralsclaim, setReferralsclaim] = useState(0);
  const [user_photo_url, setUser_photo_url] = useState(null);
  const fetchDataReferrals = async () => {
    setLoading(true);
    try {
      

      const response = await fetch(`https://drivecoin.io:3001/api/friends/${user.user.id}?page=${page}&limit=10`);
      const results = await response.json();      
      const newCards = results.map(friend => ({
        title: friend.user_name_tg,
        img: friend.photo_url,
        level: friend.referral_level,
        distance: friend.total_distance
      }));
      setCards(prevCards => [...prevCards, ...newCards]);
    } catch (error) {
      console.error('Ошибка получения данных:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && user.user.id && !initialLoad) {
      fetchDataReferrals();
      setInitialLoad(true);
    }
  }, [user, initialLoad]);

  useEffect(() => {
    if (user && user.user.id && page > 1) {
      fetchDataReferrals();
    }
  }, [page]);

  return (
    <UserContext.Provider value={{
      user, setUser,
      distance, setDistance,
      total_distance, setTotal_distance,
      cards, setCards,
      setPage, loading,
      tonConnect, setTonConnect,
      totalReferrals,setTotalReferrals, 
      referralsclaim, setReferralsclaim,
      user_photo_url, setUser_photo_url
    }}>
      {children}
    </UserContext.Provider>
  );
};
